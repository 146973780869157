export const CUSTOM_ERRORS = Object.freeze({
    ValidationError: "ValidationError",
    NotFoundError: "NotFoundError"
});

export const feilmeldinger = {
    ingenOmraderFunnet:
        "Ingen områder samsvarte med søket ditt på stedsnavn/adresse.",
    uventetFeil: "Det har oppstått en uventet feil.",
    uventetFeilProvPaNytt: "Det har oppstått en uventet feil. Prøv på nytt",
    alleredeLagtTil:
        "har allerede blitt lagt til i beredskapsområdet, og legges ikke til på nytt.",
    ingenPrestIBeredskap: "Det er ingen prest i beredskap i dette området.",
    ingenProsterLagtTilIBispedommet:
        "Det er ingen proster lagt til i bispedømmet.",
    ingenPresterLagtTilIOmradet: "Det er ingen prester lagt til i området.",
    prestErIkkeLagtTilOmrade: "Du er ikke lagt til i noen beredskapsområder. Hvis du mener dette er feil, kontakt din leder.",
    prostErIkkeLagtTilBispedomme: "Du er ikke lagt til i noen bispedommer. Hvis du mener dette er feil, kontakt din leder.",
    http: {
        404: "Denne siden eksisterer ikke. Sjekk om du har skrevet inn riktig addresse i addressefeltet.",
        401: `Du har ikke tilgang til denne tjenesten. Hvis du mener det er feil, ta kontakt med din leder eller Kirkepartner.`,
        500: "Det har oppstått en uventet feil",
    },
    sok: {
        finnerIngenPrest:
            "Det finnes ingen prest med dette navnet. Søk på nytt.",
        tomtSok: "Søket kan ikke være tomt. Skriv inn navnet på en prest.",
        upresistSok:
            "Søket ditt er for upresist og gir for mange treff. Vennligst oppgi en mer nøyaktig adresse.",
    },
};


export const feilmeldingerMedInput = (input:string) => {
    return {
    prostErIkkeLagtTilIBispedommet:  {
        ikkeRegistertIBispedomme: `Du har logget inn med ${input}. Denne e-posten er ikke registrert i noen av bispedømmene i løsningen. `,
        sjekkEpost: `Sjekk at du har brukt riktig e-post og prøv igjen.`,
        forsattFeil: `Hvis du mener noe fortsatt er feil, kontakt din leder for å løse problemet. `,
        prostOgPrest: ` Du kan forsatt oppdatere din egen beredskapsstatus, ved å gå til menyen oppe til venstre og endre visning til "Prest".`,
    },
    prestErIkkeLagtTilOmrade:  `Du har logget inn med ${input}. Denne e-posten er ikke registrert i noen av beredskapsområdene i løsningen. \
                                Sjekk at du har brukt riktig e-post og prøv igjen. \
                                Hvis du mener noe fortsatt er feil, kontakt din leder for å løse problemet. `,
    }
}

export const toastVarslinger = {
    varselPaaIBispedommet: `Du får nå varsel fra dette bispedømmet.`,
    varselAvIBispedommet:  `Du får ikke varsel fra dette bispedømmet.`,
    varselPaaIBeredskapsomradet: `Du får nå varsel fra dette beredskapsområdet.`,
    varselAvIBeredskapsomradet:  `Du får ikke varsel fra dette beredskapsområdet.`,
    uventetFeil: "Det har oppstått en uventet feil.",
};
